<template>
  <div class="home">
    <h1 class="site-slogon m-0 text-left text-md-right" v-html="$t('siteSlogon')"></h1>

    <!-- <p>{{ $t("main.message") }}</p> -->
    <FrontSwiper />
  </div>
</template>

<script>
// @ is an alias to /src
import FrontSwiper from '../components/FrontSwiper'

export default {
  name: 'Home',
  metaInfo: {
    title: 'IRIX DESIGN',
    titleTemplate: null
  },
  data() {
    return {
      // locale: ''
    }
  },

  computed: {
    // pageContent () {
    //   const content = {}
    //   this.$store.getters
    //     .getPage(16)
    //     .polylang_translations.forEach(({ id, locale }) => {
    //       content[locale] = this.$store.getters.getPage(id)
    //     })
    //   return content[this.$i18n.locale]
    // }
  },
  methods: {},
  components: {
    FrontSwiper
  }
}
</script>

<style scoped lang="scss">
.site-slogon {
  position: absolute;
  z-index: 2;
  height: calc(50% - 20px);
  display: flex;
  align-items: center;
  right: inherit;
  pointer-events: none;
  font-size: 1.8rem;
  left: 20%;
}

@media (min-width: 768px) {
  .site-slogon {
    right: calc(60% + 1rem);
    left: inherit;
    font-size: 2.5rem;
  }
}
</style>
