<template>
  <div id="app" v-if="dataReady" ref="app">
    <!-- <LocaleSwitcher /> -->
    <Nav />
    <!-- <transition @leave="leave"> -->
    <router-view />
    <!-- </transition> -->

    <Footer v-if="$route.path == '/'" />
    <SiteFooter v-if="$route.path != '/' && $route.path != '/case-studies'" />
  </div>

  <div v-else>error</div>
</template>

<script>
// import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
import SiteFooter from '@/components/SiteFooter'

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | IRIX DESIGN'
  },
  computed: {
    dataReady() {
      return true
      // return !!(this.$store.state.data.allPagesLoaded &&
      // this.$store.state.data.allCasesLoaded &&
      // this.$store.state.data.allWorksLoaded
      // && this.$store.state.data.allTypesLoaded
      // )
    }
  },
  components: {
    // LocaleSwitcher,
    Nav,
    Footer,
    SiteFooter
  },
  methods: {
    getVH() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      console.log(vh)
    },
    enter() {
      // console.log('enter')
      console.log('enter: ' + this.$route.name)
    },
    leave(el, done) {
      console.log(el)
      if (this?.$refs?.bgVid) {
        const target = this?.$refs?.bgVid
        // console.log('leave: ' + done)
        this
          .$anime
          .timeline()
          .add({
            target,
            translateX: 250,
            easing: 'easeOutExpo'
          })
          .add({
            target,
            translateY: 250,
            easing: 'easeOutExpo'
          })
      }
      done()
    }
  },
  mounted() {
    this.getVH()
    window.addEventListener('resize', () => {
      this.getVH()
    })
  }
}
</script>

<style lang="scss">
</style>
