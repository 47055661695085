<template>
  <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide
      v-for="({ title, description, moreBtn }, i) in $t('frontSlides')"
      :key="i"
    >
      <div class="slide-content">
        <img :src="slidesExInfo[i].img" alt="" data-swiper-parallax="60%" />

        <div class="main-text" data-swiper-parallax="100%">
          <div ref="targetEl" class="text-wrapper">
            <h2>{{ title }}</h2>
            <p>{{ description }}</p>
            <router-link class="learn-btn" :to="slidesExInfo[i].link">{{
              moreBtn
            }}</router-link>
          </div>
        </div>
      </div>
    </swiper-slide>

    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
export default {
  props: ['slides'],
  data () {
    return {
      swiperOptions: {
        parallax: true,
        speed: 800,
        loop: false,
        resizeObserver: true,
        pagination: {
          el: '.swiper-pagination',
          renderBullet: function (index, className) {
            return `<span class="${className}">0${index + 1}</span>`
          },
          clickable: true
        },
        on: {
          slideChange: el => {
            const targets = this.$refs.targetEl[el.activeIndex].childNodes
            this.$anime({
              targets,
              translateY: [50, 0],
              easing: 'easeOutExpo',
              opacity: [0, 1],
              duration: 1000,
              delay: this.$anime.stagger(100, { start: 500 })
            })
            // bus.$emit('hideVideo2', el.activeIndex)
          }
        }
      },
      slidesExInfo: {
        0: {
          img: require('@/assets/img/home/01.jpg'),
          link: '/about'
        },
        1: {
          img: require('@/assets/img/home/02.jpg'),
          link: '/services'
        },
        2: {
          img: require('@/assets/img/home/03.jpg'),
          link: '/works'
        },
        3: {
          img: require('@/assets/img/home/04.jpg'),
          link: '/case-studies'
        }
      }
    }
  },
  computed: {
    filteredSlides () {
      if (!this.slides) return
      const filteredSdlies = []
      Object.keys(this.slides).forEach(el => {
        if (el.includes('group_slide')) {
          filteredSdlies.push(this.slides[el])
        }
      })
      return filteredSdlies
    },
    allSlides () {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {}
}
</script>

<style lang="scss">
.home {
  .swiper-container {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    position: relative;
    .text-wrapper p {
      max-width: 400px;
    }
    .swiper-wrapper {
      height: 100%;
      // .swiper-slide {
      //   background: red;
      // }
      .swiper-slide {
        overflow: hidden;
        .slide-content {
          height: 100%;
          width: 100%;
          img {
            position: absolute;
            object-fit: cover;
            z-index: -1;
            top: 0;
            width: 100%;
            height: 100%;
          }
          .main-text {
            width: 100%;
            position: absolute;
            top: 50%;

            .text-wrapper {
              width: 60%;
              position: absolute;
              right: inherit;
              left: 20%;
            }
          }
        }
      }
    }
    .swiper-pagination-bullets {
      position: absolute;
      // color: #fff;
      display: flex;
      width: 100%;
      align-items: flex-end;
      left: 20%;
      right: inherit;
      top: calc(50% - 100px);
      bottom: inherit;
      height: 75px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: $white;
      }
    }
    .swiper-pagination-bullet {
      font-size: 1.5rem;
      text-align: left;
      width: auto;
      height: inherit;
      background: none;
      opacity: 0.5;
      transition: all 0.5s ease;
      display: flex;
      align-items: flex-end;
      margin-right: 10% !important;
      margin-left: 0 !important;
      transform-origin: 30% 90%;
    }
    .swiper-pagination-bullet-active {
      // font-size: 2rem;
      transform: scale(1.5);
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .swiper-container {
      .swiper-pagination-bullets {
        width: 60%;
        left: inherit;
        right: 0;
      }
      .swiper-pagination-bullet {
        font-size: 2.5rem;
      }
      .swiper-pagination-bullet-active {
        // font-size: 4rem;
        opacity: 1;
      }
    }

    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .slide-content {
            .main-text {
              .text-wrapper {
                right: 0;
                left: inherit;
              }
            }
          }
        }
      }
    }
  }
}
</style>
