<template>
  <div class="main-menu">
    <b-row class="h-100 main-menu-desk">
      <b-col>
        <!-- <ul
          class="p-0 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <li ref="items" v-for="(item, i) in block1" class="list-1" :key="i">
            <router-link :to="item.slug">
              <h2 @click="closeMenu()">{{ item.title }}</h2>
            </router-link>
            <p class="d-none d-md-block">{{ item.excerpt }}</p>
          </li>
        </ul>-->
        <b-row class="block block1">
          <b-col class="text-center center-content flex-column">
            <router-link @click.native="closeMenu()" :to="`/${$t('menuItems')[0].slug}`">
              <h2>{{ $t("menuItems")[0].title }}</h2>
            </router-link>
            <p class="d-none d-md-block">{{ $t("menuItems")[0].excerpt }}</p>
          </b-col>
        </b-row>
        <b-row class="block block1-1">
          <b-col class="text-center center-content flex-column">
            <router-link @click.native="closeMenu()" :to="`/${$t('menuItems')[1].slug}`">
              <h2>{{ $t("menuItems")[1].title }}</h2>
            </router-link>
            <p class="d-none d-md-block">{{ $t("menuItems")[1].excerpt }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="d-none d-md-block">
        <b-row class="h-50">
          <b-col class="block block2 center-content">
            <router-link @click.native="closeMenu()" :to="`/${$t('menuItems')[3].slug}`">
              <h2>{{ $t("menuItems")[3].title }}</h2>
            </router-link>
          </b-col>
          <b-col class="block block3 center-content">
            <router-link @click.native="closeMenu()" :to="`/${$t('menuItems')[4].slug}`">
              <h2>{{ $t("menuItems")[4].title }}</h2>
            </router-link>
          </b-col>
          <b-col>
            <b-row class="block block4 h-50 center-content">
              <span class="p-3" style="cursor: pointer" @click="changeLocale(tc), closeMenu()">{{ localeNames[tc]
              }}</span>
            </b-row>
            <b-row class="block block5 h-50 center-content">
              <span class="p-3" style="cursor: pointer" @click="changeLocale(sc), closeMenu()">{{ localeNames[sc]
              }}</span>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="block block6 h-50 center-content">
          <!-- <h2 class="d-none">{{ $t("menuItems")[2].title }}</h2> -->
        </b-row>
      </b-col>
    </b-row>
    <div class="main-menu-mobile d-flex d-md-none flex-column h-100 justify-content-center align-items-center">
      <ul class="p-0">
        <li v-for="item in $t('menuItems')" :key="item.id">
          <router-link @click.native="closeMenu()" :to="`/${item.slug}`">
            <h2>{{ item.title }}</h2>
          </router-link>
        </li>
      </ul>
      <div>
        <span class="p-3" style="cursor: pointer" @click="changeLocale(tc), closeMenu()">{{ localeNames[tc] }}</span>
        <span class="p-3" style="cursor: pointer" @click="changeLocale(sc), closeMenu()">{{ localeNames[sc] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from '@/main'

export default {
  name: 'MainMenu',
  data() {
    return {
      localeNames: {
        en_US: 'EN',
        zh_CN: '简体',
        zh_HK: '繁體'
      }
    }
  },
  computed: {
    // block1 () {
    //   return window.innerWidth > 768
    //     ? this.$t('menuItems').slice(0, 2)
    //     : this.$t('menuItems')
    // },
    tc() {
      return this.$i18n.locale !== 'zh_HK' ? 'zh_HK' : 'en_US'
    },
    sc() {
      return this.$i18n.locale !== 'zh_CN' ? 'zh_CN' : 'en_US'
    }
  },
  methods: {
    closeMenu() {
      bus.$emit('closeMainMenu')
    },
    changeLocale(val) {
      this.$i18n.locale = val
      document.documentElement.setAttribute('lang', val)
      // bus.$emit('clearSubType')

      bus.$emit('updateWorkLocate')
    }
  },
  mounted() { }
}
</script>

<style lang="scss">
.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba($color: #000000, $alpha: 0.5);
  padding: 0px;

  .main-menu-desk {
    display: none;

    ul {
      list-style: none;
      text-align: center;
    }

    p {
      margin: 0;
    }

    // [class*="block"]:hover {
    //   background: rgba($color: #1a1a1a, $alpha: 1);
    // }

    .block {
      background: rgba($color: #1a1a1a, $alpha: 0.9);
      border: 1px solid #000;
    }

    .block:hover {
      background: rgba($color: #1a1a1a, $alpha: 1);
    }

    .block1 {
      // background: rgba($color: #1a1a1a, $alpha: 0.9);
      height: 50%;
    }

    .block1-1 {
      // background: rgba($color: #1a1a1a, $alpha: 0.7);
      height: 50%;
    }

    // .block2 {
    //   background: rgba($color: #252525, $alpha: 0.9);
    // }
    // .block3 {
    //   background: rgba($color: #1a1a1a, $alpha: 0.6);
    // }
    // .block4 {
    //   background: rgba($color: #1a1a1a, $alpha: 0.8);
    // }
    // .block5 {
    //   background: rgba($color: #494949, $alpha: 0.95);
    // }
    // .block6 {
    //   background: rgba($color: #252525, $alpha: 0.65);
    // }
  }

  .main-menu-mobile {
    background: rgba($color: #1a1a1a, $alpha: 0.7);

    ul {
      list-style: none;

      li:nth-child(3) {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .main-menu {
    padding: 80px;

    .main-menu-desk {
      display: flex;
    }

    .main-menu-mobile {
      display: none;
    }

    .block1 {
      li {
        h2 {
          font-size: 2rem;
        }

        &::after {
          display: block;
        }
      }
    }
  }
}
</style>
