import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import swiper from './plugins/swiper'
import 'normalize.css'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAnime from 'vue-animejs'

import SVGInjectorVue from 'svginjector-vue'

import UUID from 'vue-uuid'

import VueYoutube from 'vue-youtube'

import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles

import VueMeta from 'vue-meta';
// ..
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 200, // values from 0 to 3000, with step 50ms
  duration: 800, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation

})

Vue.use(VueYoutube)

Vue.use(SVGInjectorVue)

Vue.use(VueAnime)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(UUID)

Vue.config.productionTip = false

// options 可以作額外設定
Vue.use(VueMeta);

export const bus = new Vue()
new Vue({
  router,
  store,
  i18n,
  swiper,
  render: h => h(App),
  created () {
    const getHTMLTag = document.documentElement
    getHTMLTag.setAttribute('lang', this.$i18n.locale)
    this.getCategories()
  },
  methods: {
    getCategories () {
      this.$store.dispatch('fetchCats', this.$i18n.locale)
    }
  },
  mounted () {
    bus.$on('updateWorkLocate', this.getCategories)
  }

}).$mount('#app')
