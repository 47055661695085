<template>
  <footer class="home-footer">
    <div class="d-flex align-items-center flex-wrap">
      <div class="address text-right m-0 mr-3 d-none d-md-block">
        <a :href="`tel:${$t('company.contact.phone')}`">{{ $t('company.contact.phone') }}</a>
        <br>
        <a :href="`mailto:${$t('company.contact.email')}`">{{ $t('company.contact.email') }}</a>
      </div>

      <div class="d-flex social-icons-wrapper">
        <div class="social-icons d-flex">
          <div class="js-svg-injector" v-for="({ icon, link }, i) in ssIcons" :key="i">
            <a v-if="icon" :href="link" target="_blank">
              <img :src="require(`@/assets/img/footer/social-icons/${icon}`)" />
            </a>
          </div>
        </div>
        <div>{{ $t('global.follow_us_on_wechat') }}: IRIX-Design</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      ssIcons: this.$t("company.social")
    }
  }
}
</script>

<style lang="scss">
.home-footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  padding: 22px;

  .address {
    padding-right: 1rem;
    font-size: 12px;
    border-right: 1px solid $white;
  }

  .social-icons-wrapper {
    flex-direction: column;
    align-items: end;
  }

  .social-icons {
    margin-bottom: 10px;

    .js-svg-injector {
      width: 35px;
      margin-right: 10px;

      img {
        width: 35px;
        height: 35px;
      }

      &:last-child {
        margin: 0;
      }

      &:hover {
        img {
          filter: invert(37%) sepia(64%) saturate(585%) hue-rotate(334deg) brightness(80%) contrast(91%);
        }
      }
    }
  }

  @media (min-width:768px) {
    .social-icons-wrapper {
      flex-direction: row;
      align-items: center;

      .social-icons {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
}
</style>
