<template>
  <footer class="site-footer">
    <div class="content">
      <b-row>
        <b-col cols="12" md="4">
          <router-link to="/about">
            <h2>{{ $t("menuItems")[3].title }}</h2>
          </router-link>
          <router-link to="/services">
            <h2 @click="scrollHandler()">{{ $t("menuItems")[0].title }}</h2>
          </router-link>
        </b-col>
        <b-col cols="12" md="4">
          <router-link to="/works">
            <h2>{{ $t("menuItems")[1].title }}</h2>
          </router-link>
          <!-- <router-link to="/case-study">
            <h2>{{ $t("menuItems")[2].title }}</h2>
          </router-link>-->
        </b-col>
        <b-col cols="12" md="4">
          <router-link to="/say-hello">
            <h2>{{ $t("menuItems")[4].title }}</h2>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <a target="_blank"
            href="https://www.google.ca/maps/place/Irix+Design+Group+Inc/@49.2634885,-123.1237223,17z/data=!3m1!4b1!4m2!3m1!1s0x548673c325dd02c1:0xd0860ccbf8134b47">
            <p class="m-0 mr-3 address" v-html="$t('company.address')"></p>
          </a>

          <a :href="`tel:${$t('company.contact.phone')}`">{{ $t('company.contact.phone') }}</a>
          <br />
          <a :href="`mailto:${$t('company.contact.email')}`">{{ $t('company.contact.email') }}</a>
        </b-col>
        <b-col cols="12" md="4">
          <!-- <h4>{{ $t("menuItems")[5].title }}</h4> -->
        </b-col>
        <br>
        <b-col cols="12" md="4">
          <div class="socialMedia text-left text-md-right">
            <div v-for="({ link, title }, i) in $t('company.social')" :key="i">
              <a :href="link" target="_blank">{{ title }}</a>
            </div>
            <div>{{ $t('global.follow_us_on_wechat') }}: IRIX-Design</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollHandler() {
      if (this.$route.path === "/services") {
        console.log(this.$route)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.site-footer {
  padding: 50px 0;
  position: relative;
  background: #272a2e;

  .address {
    br:nth-child(2) {
      display: none;
    }
  }

  .content {
    max-width: 960px;
  }

  .row:first-child {
    margin-bottom: 50px;
  }
}
</style>
