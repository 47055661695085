import axios from 'axios'
import i18n from '../../plugins/i18n'

// console.log(i18n.t('frontSlides', 'zh_HK'))

axios.defaults.baseURL = process.env.VUE_APP_API_URL

const param = {
  params: {
    // per_page: 10, // Only retrieve the 3 most recent blog posts.
    page: 1, // Current page of the collection.
    lang: null
  }
}

const ytParams = {
  url: 'https://www.googleapis.com/youtube/v3/playlistItems',
  params: {
    key: process.env.VUE_APP_YT_KEY,
    playlistId: 'PLi7N86K8nRxx-ZzXhcdJq_DCEu4YwYvcC',
    maxResults: '50',
    part: 'snippet,status',
    pageToken: null
  }
}

const state = {
  works: [],
  cats: [],
  subCats: [],
  youtubeVideos: []
}

const getters = {
  allWorks: state => {
    return state.works
  },
  allSubCat: state => state.subCats
}

//   getProjectTw: (state) => (id) => state.projectstw.find((projecttw) => projecttw.id === id)

const actions = {
  async fetchWorks({ commit }, pageParam) {
    if (pageParam.params.cat) {
      param.params.lang = pageParam.params.lang
      param.params.cat = pageParam.params.cat
      try {
        const response = await axios.get('/works/search/v1', param)
        // console.log(param)
        i18n.t('works').mainWorks.push({
          slug: pageParam.params.cat,
          data: response.data[0],
          next_page_url: response?.data[1]?.next_page_url
          // title: response.data[2]
        })

        commit('SET_WORKS', response.data)
      } catch (error) {
        console.log(error)
      }
    }

    if (pageParam.params.subCat) {
      try {
        const response = await axios.get('/works/search/v1', pageParam)

        i18n.t('works').subWorks.push({
          slug: pageParam.params.subCat,
          data: response.data[0],
          next_page_url: response.data[1].next_page_url,
          title: response.data[2]
        })

        commit('SET_WORKS', response.data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  async fetchCats({ commit }, locale) {
    if (!i18n.t('categories').data.length) {
      param.params.lang = locale
      param.params.parent_id = null
      const response = await axios.get('/categories/search/v1', param)
      commit('SET_CATEGORIES', response.data)
      // commit('SET_CATEGORIES_LOAD', true)
    }
    if (!i18n.t('categories').subCats.length) {
      param.params.lang = locale
      param.params.parent_id = []
      i18n.t('categories').data.forEach(({ id }) => {
        param.params.parent_id.push(id)
      })
      const response = await axios.get('/categories/search/v1', param)
      commit('SET_SUBCATEGORIES', response.data)
    }
  },
  async fetchYtVideo({ commit }, ytParam) {
    const response = await axios(ytParams)
    commit('SET_YTVIDEOS', response.data)
  }
}

const mutations = {
  SET_WORKS: (state, works) => {
    state.works = works
  },
  SET_CATEGORIES: (state, cats) => {
    i18n.t('categories').data = cats
    state.cats = cats
  },
  SET_SUBCATEGORIES: (state, subCatsVal) => {
    i18n.t('categories').subCats = subCatsVal
    state.subCats = subCatsVal
  },
  SET_YTVIDEOS: (state, videos) => {
    state.youtubeVideos = videos
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
