<template>
  <nav>
    <div class="nav-wrapper">
      <router-link class="site-logo d-flex" to="/">
        <img src="@/assets/img/logo/irix_head_logo.svg" alt="" />
        <!-- <img
          class="site-logo-m d-block d-md-none"
          src="@/assets/img/logo/irix_head_logo_vert.svg"
          alt=""
        /> -->
      </router-link>

      <div class="menu">
        <div class="hamburger-wrapper" @click="isActive = !isActive">
          <div class="menu-icon" :class="{ 'menu-icon-active': isActive }">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
        <MainMenu v-if="isActive" />
      </div>
    </div>
  </nav>
</template>

<script>
import MainMenu from '@/components/MainMenu'
import { bus } from '@/main'
export default {
  data () {
    return {
      isActive: false
    }
  },
  methods: {},
  components: {
    MainMenu
  },
  mounted () {
    bus.$on('closeMainMenu', () => {
      console.log('closeMainMenu')
      this.isActive = false
    })
  }
}
</script>

<style lang="scss">
nav {
  position: fixed;
  z-index: 101;
  width: 100%;
  .nav-wrapper {
    padding: 22px;
    display: flex;
    justify-content: space-between;
  }
  .site-logo {
    width: 300px;
    z-index: 1001;

    // display: flex;
    // align-items: center;
    // &-m{
    //   width: 50px;
    // }
  }
  // .router-link-active {
  //   display: flex;
  // }

  .hamburger-wrapper{
    padding: 10px;
    position: relative;
    z-index: 1001;
    cursor: pointer;
    transform: translateX(12px);
  }
  .menu-icon {
    width: 18px;
    position: relative;
    height: 18px;
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $white;
      position: absolute;
      transition: all 0.3s ease;
    }
    .dot:nth-child(1) {
      left: 0;
      top: 0;
    }
    .dot:nth-child(2) {
      right: 0;
      top: 0;
    }
    .dot:nth-child(3) {
      left: 0;
      bottom: 0;
    }

    .dot:nth-child(4) {
      right: 0;
      bottom: 0;
    }
    &-active {
      .dot {
        width: 150%;
        border-radius: 0;
        height: 2px;
      }
      .dot:nth-child(1) {
        top: 50%;
        left: -22.5%;
        transform: rotate(45deg);
      }
      .dot:nth-child(2) {
        top: 50%;
        right: -22.5%;
        transform: rotate(-45deg);
      }
      .dot:nth-child(3),
      .dot:nth-child(4) {
        width: 0;
        height: 0;
      }
    }
  }
}
</style>
