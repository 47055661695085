import Vue from 'vue'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Swiper 6.x
import { Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay, Parallax, EffectFade } from 'swiper/core'

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'

// Swiper modules
SwiperClass.use([Pagination, Navigation, Mousewheel, Autoplay, Parallax, EffectFade])

// import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'

Vue.use(getAwesomeSwiper(SwiperClass))

export default {

}
