import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import('../views/Works3/Works')
  },
  // {
  //   path: '/works/branding',
  //   name: 'Branding',
  //   component: () => import('../views/Works3/Work/Branding')
  // },
  // {
  //   path: '/works/branding/:subCat',
  //   name: 'BrandingSub',
  //   component: () => import('../views/Works3/Work/BrandingSub')
  // },
  // {
  //   path: '/works/signage',
  //   name: 'Signage',
  //   component: () => import('../views/Works3/Work/Branding')
  // },
  // {
  //   path: '/works/signage/:subCat',
  //   name: 'SignageSub',
  //   component: () => import('../views/Works3/Work/BrandingSub')
  // },
  {
    path: '/works/video/:vCat/',
    name: 'video',
    component: () => import('../views/Works3/Work/Videos')
  },
  {
    path: '/works/:cat',
    name: 'cat',
    component: () => import('../views/Works3/Work/Branding')
  },
  {
    path: '/works/:cat/:subCat',
    name: 'subcat',
    component: () => import('../views/Works3/Work/BrandingSub')
  },
  {
    path: '/works/*',
    name: '404',
    component: () => import('../views/404')
  },
  {
    path: '/services',
    name: 'Our Services',
    component: () => import('../views/Services')
  },
  {
    path: '/case-studies',
    name: 'Case Studies',
    component: () => import('../views/Cases')
  },
  {
    path: '/say-hello',
    name: 'Say Hello',
    component: () => import('../views/SayHello')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
